// ContactUsPopup.js

import { useState } from 'react';
import axios from 'axios';
import style from ".././pages/Responsive.module.css";
import styles from "./ContactUsPopup.module.css";
import {URL} from '.././config'


const ContactUsPopup = ({ onClose,isInLightMode }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: ''
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [submissionMessage, setSubmissionMessage] = useState('');
  const [erroRedOrGreen,setErroRedOrGreen] = useState(true)
  

  const handleChangeName = (e) => {
    setFormData({
      ...formData,
      name: e.target.value
    });
  };

  const handleChangeEmail = (e) => {
    setFormData({
      ...formData,
      email: e.target.value
    });
  };

  const handleChangePhone = (e) => {
    setFormData({
      ...formData,
      phone: e.target.value
    });
  };

  const validateForm = () => {
    const errors = {};

    // Validate name
    if (!formData.name.trim()) {
      errors.name = 'Name is required*';
      
    }

    // Validate email
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!formData.email.trim() || !emailRegex.test(formData.email.trim())) {
      errors.email = 'Enter a valid email address*';
      
    }

    // Validate phone number
    const phoneRegex = /^\d{10}$/;
    if (!formData.phone.trim() || !phoneRegex.test(formData.phone.trim())) {
      errors.phone = 'Enter a valid 10-digit phone number*';
      
    }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0; // true or false
  };

  const handleSubmit = async () => {
    try {
      if (validateForm()) {
        // Make a POST request to the endpoint with the form data
        const response = await axios.post(`${URL}/users/send`, formData);

          
        if (response.data.err) {

          setErroRedOrGreen(false)
          // Display error message from the server
          setSubmissionMessage(response.data.err);
        } else {
          // Reset the form data after successful submission
          setFormData({
            name: '',
            email: '',
            phone: ''
          });
          setErroRedOrGreen(true)
          // Display success message
          setSubmissionMessage('Details Sent Successfully! We will get back to you soon.');
        }
      }
    } catch (error) {
      console.log('Error submitting data:', error.message);
      setErroRedOrGreen(false)
      // Display error message
      setSubmissionMessage('Error sending data. Please try again later.');
    }
  };

  const closeContactusCrossIconClicked = ()=>{
    // console.log('Cross Icon clicked in Contact us popup')
    onClose()
  }


  return (
    <div style={{ top: '75px' }} className={`${styles.contactUsPopup} ${isInLightMode?"":styles["dark-mode"]}`}>

      <div className={`${styles.contactUs} ${isInLightMode?'':"darkModeGradientText"} ContactUpPopContainer`}>
        <p className={styles.popuptext}>Contact us</p>
        <img src='/cross-icon.png' alt='Cross Icon' onClick={closeContactusCrossIconClicked} className={`${styles['cross-icon']} ${isInLightMode?'':style['dark-modeImage']}`} />
      </div>
      
      <div className={styles.name}>
        <label htmlFor="name" className={styles.yourName}>Your Name</label>
        <input
          type="text"
          placeholder="Enter Your Name"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChangeName}
          className={`${isInLightMode?'':'dark-mode'}`}
        />       
      </div>
      {validationErrors.name && <div className={styles.errorText}>{validationErrors.name}</div>}
    



      <div className={styles.name}>
        <label htmlFor="email" className={styles.yourName}>Your Email</label>
        <input
          type="email"
          placeholder="Enter Your Email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChangeEmail}
          className={`${isInLightMode?'':'dark-mode'}`}
        />
      </div>
      {validationErrors.email && <div className={styles.errorText}>{validationErrors.email}</div>}

      <div className={styles.name}>
        <label htmlFor="phone" className={styles.yourName}>Your Phone Number</label>
        <input
          type="tel"
          placeholder="Enter Your Phone Number"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleChangePhone}
          className={`${isInLightMode?'':'dark-mode'}`}
        />
      </div>
      {validationErrors.phone && <div className={styles.errorText}>{validationErrors.phone}</div>}

      <div className={styles.buttonCta}>
        <button className={styles.getStarted} onClick={handleSubmit}>Get Started</button>
      </div>
      
      {submissionMessage && (erroRedOrGreen? <div className={styles.greenText}>{submissionMessage}</div>:<div className={styles.redText}>{submissionMessage}</div>)}
    </div>
  );
};

export default ContactUsPopup;
