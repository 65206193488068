import { useContext } from "react";
import {MyContext} from "../MyContext/index"; 
import './index.css';



const PackagePopup = ({
  showModal,
  setShowModal,
  formData,
  setFormData,
  isNameValid,
  emailValid,
  phoneValid,
  isCityValid,
  erroRedOrGreen,
  submissionMessage,
  handleSendButtonClick,
  setSubmissionMessage,setEmailValid,setPhoneValid,setIsNameValid,setIsCityValid,
  setErroRedOrGreen,showPopup,setShowPopup,showPopupDetail,setShowPopupDetail
  
}) => {

  // console.log(showPopup,'In PackagePopup component')
  // console.log(formData,'In PackagePopup component') 

  const isInLightModeContextValue = useContext(MyContext)
  // console.log(isInLightModeContextValue,'In PackagePopup component value of isInLightModeContextValue')

  const closeButtonClicked = ()=>{
    if(showPopup===true){
      setShowPopup(false)
    }
    else if(showModal===true){
      setShowModal(false)
    } 
    else if(showPopupDetail){
      setShowPopupDetail(false)
    }
    
    setEmailValid(true)
    setPhoneValid(true)
    setIsNameValid(true)
    setIsCityValid(true)
    setErroRedOrGreen(true)
    setSubmissionMessage('')
  }

 

  return (
    (showModal || showPopup || showPopupDetail) && (
    <div className="popup-background">
      <div className="popup-content">

      <div className={`package-popup-main-bg ${isInLightModeContextValue?'':'dark-mode-package-popup'}`}>
        <div className='package-popup-header'>
            <p className={`package-popup-header-text ${isInLightModeContextValue?'':'dark-headtext'}`}>Contact us</p>
            <img src='/cross-icon.png' alt='Cross Icon' onClick={closeButtonClicked} className={`close ${isInLightModeContextValue?'':'dark-mode-cross'}`} />
            {/* <span className="close" onClick={() => setShowModal(false)}>&times;</span> */}
        </div>

        <div className='name'>
            <label htmlFor="name" className='yourName'>Your Name</label>
            <input type="text" placeholder="Enter Your Name" id="name" name="name" value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className={`${isInLightModeContextValue?'':'dark-mode-package-popup'}`}
            />       
        </div>
        {!isNameValid && <p className="warning">Please enter your name*</p>}

        <div className='name'>
            <label htmlFor="email" className='yourName'>Your Email</label>
            <input
                type="email"
                placeholder="Enter Your Email"
                id="email"
                name="email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                className={`${isInLightModeContextValue?'':'dark-mode-package-popup'}`}
            />
        </div>
        {!emailValid && <p className="warning">Please enter a valid email address*</p>}



        <div className='name'>
        <label htmlFor="phone" className='yourName'>Your Phone Number</label>
        <input
          type="tel"
          placeholder="Enter Your Phone Number"
          id="phone"
          name="phone"
          value={formData.phoneNumber}
          onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
          className={`${isInLightModeContextValue?'':'dark-mode-package-popup'}`}
        />
        </div>
        {!phoneValid && <p className="warning">Please enter a valid 10-digit phone number*</p>}



        <div className='name'>
            <label htmlFor="city" className='yourName'>Your City</label>
            <input
                type="text"
                placeholder="Enter Your City"
                id="city"
                name="city"
                value={formData.city}
                onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                className={`${isInLightModeContextValue?'':'dark-mode-package-popup'}`}
            />
        </div>
        {!isCityValid && <p className="warning">Please enter your city*</p>}

        <div className='package-popup-button-bg'>
            <button className='package-popup-button' onClick={handleSendButtonClick}>Send</button>
        </div>

        {submissionMessage && (erroRedOrGreen? <div className='greenText'>{submissionMessage}</div>:<div className='redText'>{submissionMessage}</div>)}
      </div>
      </div>
    </div>

    )
  );
};

export default PackagePopup;
