
import styles from "../../pages/Responsive.module.css";


/* importing sun and moon images */
import lightmodesun from "../../images/lightModeSunNew.png"
import lightmodemoon from "../../images/moon.png"
import darkmodesun from "../../images/sunDarkModeNew.png"
import darkmodemoon from "../../images/Moonselected.png"
/* importing sun and moon images */



const LightDarkModes = ({isInDarkMode,isInLightMode,setisInLightMode,setisInDarkMode}) => {
    const sunClicked= ()=>{
        console.log("sunlight button clicked")
        if(isInLightMode){
          return;
        }
        setisInLightMode(true)
        setisInDarkMode(false)
      }
    
      const moonClicked=()=>{
        console.log("moonlight button clicked");
        if(isInDarkMode){
          return;
        }
        setisInLightMode(false)
        setisInDarkMode(true)
      }
    
  return (
    <div className={styles.toggleContainer}>
    <button type="button" className={`${styles.lightmodesunButton} ${isInLightMode?styles["light-mode"]:styles["dark-mode"]}`} onClick={sunClicked} >
      <img src={isInLightMode?lightmodesun:darkmodesun} alt="lightmodesun" />
    </button>
    <button type="button" className={`${styles.lightmodemoonButton} ${isInLightMode?styles["light-mode"]:styles["dark-mode"]}`} onClick={moonClicked} >
      <img src={isInDarkMode?darkmodemoon:lightmodemoon} alt="lightmodesun" />
    </button>
  </div>
  )
}

export default LightDarkModes
