import { useState,useContext } from "react"
import './index.css'
import { MyContext } from "../MyContext/index"

const SuccessViewProductDetails = ({selectedProduct,setOptionValue}) => {
    
    const isLightModeValue = useContext(MyContext)

    const {name,detailedDescription,price,specifications,options} = selectedProduct
    // const {color,dimensions,frequency} = specifications

    // console.log(specifications,'specifications In SuccessViewProductDetails')

    const optionsValue= options.length>0?options[0]._id:null
    

    const [optionPrice,setOptionPrice] = useState(price)
   
    const [activeTypeId,setActiveTabId] = useState(optionsValue)

    const [isReadMore,setIsReadMore] = useState(false)


    const typeCardClicked = (event)=>{
        // console.log(event.target.accessKey,'typeCardClicked')
        const clickedType = options.find((eachOption)=>(
            event.target.accessKey===eachOption._id
        ))
        // console.log(clickedType)
        setOptionPrice(clickedType.price)
        setOptionValue(clickedType.label)
        setActiveTabId(clickedType._id)
    }

    const readBtnClicked = ()=>{
      setIsReadMore(!isReadMore)
    }

  return (
    <div className='selected-detailed-desc-bg'>
    <h1 className='selected-detailed-desc-head'>{name}</h1>
    <p className={`selected-detailed-desc-para ${isReadMore?'show':'hide'}`}>{detailedDescription}</p>
    <p className={`readAction`} onClick={readBtnClicked}>{isReadMore?"Show less":"Read more"}</p>
    <p className={`selected-detailed-desc-price ${isLightModeValue?'':'dark-mode-text'}`}>{`₹${optionPrice}/-`}</p>
    <div>
      <h1 className={`selected-detailed-desc-Specifications-head ${isLightModeValue?'':'dark-mode-text'}`}>Specifications</h1>
      {
        specifications.map((eachSpec)=>(
          <p key={eachSpec._id} className={`selected-detailed-desc-Specifications-outer ${isLightModeValue?'':'dark-mode-text'}`}>{eachSpec.key}: <span className='selected-detailed-desc-Specifications-inner'>{eachSpec.value}</span></p>
        ))
      }
    </div>
    
    <div className="type-card-main-bg">
      {options.length>0?<p className={`selected-detailed-desc-type-head ${isLightModeValue?'':'dark-mode-text'}`}>Type</p>:null}
      <div className='type-card-bg'>
      {
        options.length>0
        ?options.map((eachOption)=>(
        <div className={`type-card ${activeTypeId===eachOption._id?'active-type-card':''}`} accessKey={eachOption._id} key={eachOption._id} onClick={typeCardClicked}>
          {/* {console.log(eachOption._id)} */}
          <p className='type-card-desc' accessKey={eachOption._id}>{eachOption.label}</p>                           
        </div>
        ))
        :null
      }
       </div>
    </div>
</div>
  )
}

export default SuccessViewProductDetails
