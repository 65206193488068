import { useState,useContext } from 'react';
import {useNavigate} from 'react-router-dom'
import axios from 'axios';
import Select from 'react-select'
import { MyContext } from '../MyContext/index'
import {URL} from '../.././config'



import PackagePopup from '../PackagePopup/index'

import './index.css';




const EachProductCard = ({ eachProduct }) => {

  // console.log('In EachProductCard/eachProduct',eachProduct)
  const isInLightModeContextValue = useContext(MyContext)

  const {id,name,image,price,options,description}  = eachProduct
  // console.log('In EachProductCard/options',options)
  // console.log(isInLightModeContextValue,'in eachproduct')
  const defaultOption = options.length > 0 ? options[0] : null;
  // console.log(defaultOption) //{value:"..",label:".."}

  const activeOption = defaultOption!==null ? defaultOption.value : description 
  

  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', phoneNumber: '', city: '',productNameValue:'' });
  const [emailValid, setEmailValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const [isNameValid,setIsNameValid] = useState(true)
  const [isCityValid,setIsCityValid] = useState(true)
  const [submissionMessage, setSubmissionMessage] = useState('');
  const [erroRedOrGreen,setErroRedOrGreen] = useState(true)

  const [optionValue,setOptionValue]=useState(activeOption)
  const [optionPrice,setOptionPrice] = useState(price)
  
  const enquireNowClicked = () => {
    setFormData({...formData,productNameValue:name})
    if(description!==''){
      setOptionValue(description)
    }
    setShowPopup(true);
  };

  const optionClicked = (event)=>{
    // console.log('optionClicked',event.price)
    setOptionValue(event.value)
    setOptionPrice(event.price)
  }

  

  const handleSendButtonClick = async () => {

    if (!validateInputs()) return;

    

    const requestData = {
      ...formData,
      productNameValue:formData.productNameValue,
      productTypeValue: optionValue,
    };
    // console.log(requestData)



    // Send requestData to backend API using fetch or Axios
    try {
      const responseCustomer = await axios.post(`${URL}/users/customizedUser`,requestData)
      console.log(responseCustomer)

        // Check if the response contains an error 
        if (responseCustomer.data.err) {

          setErroRedOrGreen(false)
          // Display error message from the server
          setSubmissionMessage(responseCustomer.data.err);
        } else {
          // Reset the form data after successful submission
          setFormData({ name: '', email: '', phoneNumber: '', city: '' });
          setErroRedOrGreen(true)
          // Display success message
          setSubmissionMessage('Details Sent Successfully! We will get back to you soon.');
        }
        
    } catch (error) {
      console.log('Error submitting data:', error.message);
      setErroRedOrGreen(false)
      setSubmissionMessage('Error while submitting data');
    }
    
  };



  const validateInputs = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\d{10}$/;
  
    setEmailValid(emailPattern.test(formData.email));
    setPhoneValid(phonePattern.test(formData.phoneNumber));
    setIsNameValid(formData.name.trim() !== '');
    setIsCityValid(formData.city.trim() !== '');
  
    return (
      formData.name.trim() !== '' &&
      formData.city.trim() !== '' &&
      emailPattern.test(formData.email) &&
      phonePattern.test(formData.phoneNumber)
    );
  };

  const navigate = useNavigate();

  const handleProductDetails=()=>{
    navigate(`/products/details/${name.toLowerCase().replace(/\s+/g, '')}/${id}`)
  }
  

  return (
    <>
   
    <div className="product">

      
      <img src={image} alt={name} className='product-image' onClick={handleProductDetails} />
      <p className={`product-info-name ${isInLightModeContextValue?'':'dark-text'}`} onClick={handleProductDetails}>{name}</p>
      
      

      <div className='content-product-bg'>
        {
          options.length===0 ?
          <p className='description-content-product'>{description}</p> :
          <Select defaultValue={defaultOption}
           className={`drop-down-content-product custom-select`}
           options={options}
           onChange={optionClicked}
          />
        }
        
        <p className='price-content-product'>{`₹${optionPrice}`}</p>
        {/*<p className='price-content-product'>{price}</p>*/}
        <div className='buttons-content-product'> 
            <button type='button' className='add-btn-content-product'>Add to Package</button>
            <button type='button' className='enquire-btn-content-product' onClick={enquireNowClicked}>Enquire Now</button>
        </div>
      </div>

    </div>


    {showPopup && <PackagePopup
     showPopup={showPopup}
     formData={formData}
     setFormData={setFormData}
     emailValid={emailValid}
     phoneValid={phoneValid}
     isCityValid={isCityValid}
     isNameValid={isNameValid}
     submissionMessage={submissionMessage}
     erroRedOrGreen={erroRedOrGreen}
     setShowPopup={setShowPopup}
     setEmailValid={setEmailValid}
     setPhoneValid={setPhoneValid}
     setIsNameValid={setIsNameValid}
     setIsCityValid={setIsCityValid}
     setErroRedOrGreen={setErroRedOrGreen}
     setSubmissionMessage={setSubmissionMessage}
     handleSendButtonClick={handleSendButtonClick}
      />}


    </>
  );
};

export default EachProductCard;
