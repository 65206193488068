import { useContext } from "react";
import {MyContext} from "../MyContext/index"; 
import PackagesSection from "../PackagesSection/index";
import About from "../About/index"
import Clicks from "../Clicks/index";
import styles from "../../pages/Responsive.module.css";
import "./index.css"


const Main=(props)=> {
  const {openContactUsPopup}=props
  const isInLightModeContextValue = useContext(MyContext)
  // console.log(props,'props In Main Component')
  // const {history} = props
  // console.log(history,'history In Main Component')

  return (
    <>
    <section className={`${styles.contentBelowHeader} ${styles.heroSection} ${isInLightModeContextValue ? styles["lightModeGridImage"] : styles["darkModeGridImage"]}`} id="Hero Section">
    <main
      className={`${styles.experienceTheFutureOfHomeParent} `}
      id="Hero Text and 3d"
    >
      <div className={`${styles.experienceTheFuture} ${isInLightModeContextValue?'':"darkModeGradientText"}`}>
        Experience the future of home living
      </div>
      <div className={`${styles.experienceTheFuture1} ${isInLightModeContextValue?'':"darkModeGradientText"}`}>
        Experience the future of home living
      </div>
      <div className={`${styles.experienceTheFuture2} ${isInLightModeContextValue?'':"darkModeGradientText"}`}>
        Experience the future of home living
      </div>
      <div className={styles.house3d}>
        <img
          className={`${styles.houseModelView1}`}
          alt=""
          src="/house-model-view-1@2x.png"
        />

        {/* image hover temperature code start on january 18th by rohith */}
        <div className={styles.temperatureContainer}>
          <img
          className={styles.tempratureIcon}
          alt=""
          src="/temprature@2x.png"
          />
          <div className={styles.aiText}><p className={styles.textAi}>Control your temperature with AI</p></div>
        </div>
        {/* image hover temperature code end on january 18th by rohith */}


        {/* image hover tv code start on january 18th by rohith */}
        <div className={styles.tvContainer}>
          <img className={styles.tvIcon} alt="" src="/tv@2x.png" />
          <div className={styles.aiTextTv}>
            <p className={styles.textAiTv}>Save electricity by turning off devices on time through Ai</p>
            
          </div>
        </div>
        {/* image hover tv code end on january 18th by rohith */}


        {/* image hover lights code start on january 18th by rohith */}
        <div className={styles.lightsContainer}>
        <img className={styles.lightIcon} alt="" src="/light@2x.png" />
        <div className={styles.aiTextLights}><p className={styles.textAiLights}>Control your lights through AI</p></div>
        </div>
        {/* image hover lights code end on january 18th by rohith */}
      </div>
      <div className={styles.house3DMobileViewBg}>
        <img
        className={styles.houseModelView11}
        alt=""
        src="/house-model-view-1@2x.png"
        />
        <Clicks/>
      </div>
    </main>


    <button className={` ${styles.navGap} contactBtnMobile`} onClick={openContactUsPopup}>
      <div className={styles.contactUs}>Contact us</div>
    </button>
  </section>

  <About />
  <PackagesSection/>

  </>

  )
}

export default Main
