import { useContext } from "react";
import {MyContext} from "../MyContext/index"; 
import minusIcon from '../../images/minus.png' 
import plusIcon from '../../images/plus.png'
// import styles from "../../pages/Responsive.module.css";
import './index.css'


export default function FAQsQuestionsList(props) {
    const isInLightModeContextValue = useContext(MyContext)
    // console.log(isInLightModeContextValue,"In FAQsQuestionsList component")
    const { eachQAObject, isOpen, onToggle } = props;
    const { id, question, answer } = eachQAObject;
  
    const handleToggleClick = () => {
      onToggle(id);
    };
  
    return (
      <div className="ulQuestionsListBg">
        <div className="liQuestionsListBg">
          <div className="questionContentBg">
            <p className="qustionText">{question}</p>
            <img
              src={isOpen ? minusIcon : plusIcon}
              alt={isOpen ? "MinusIcon" : "PlusIcon"}
              className={`plusIcon ${isInLightModeContextValue?'':'inDarkModeIconMinusAndPlus'}`}
              onClick={handleToggleClick}
            />
          </div>
          {isOpen &&
            <p className={`answerContentBgPara ${isInLightModeContextValue?'':'inDarkModeIconMinusAndPlus'}`}>{answer}</p>
          }
          <hr className="questionsLine" />
        </div>
      </div>
    );
}
