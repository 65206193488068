import "./index.css"


const Clicks=()=> {
  return (
    <>
        {/* image hover temperature code start on january 18th by rohith */}
        <div className={`temperatureContainerMobile`}>
          <img
          className={`tempratureIconMobile`}
          alt=""
          src="/temprature@2x.png"
          />
          <div className="aiTextMobile"><p className="textAiMobile">Control your temperature with AI</p></div>
        </div>
        {/* image hover temperature code end on january 18th by rohith */}


        {/* image hover tv code start on january 18th by rohith */}
        <div className={`tvContainerMobile`}>
          <img className={`tvIconMobile`} alt="" src="/tv@2x.png" />
          <div className="aiTextTvMobile">
            <p className="aiTextTvMobileDesc">Save electricity by turning off devices on time through Ai</p>
          </div>
        </div>
        {/* image hover tv code end on january 18th by rohith */}


        {/* image hover lights code start on january 18th by rohith */}
        <div className={`lightsContainerMobile`}>
            <img className={`lightIconMobile`} alt="" src="/light@2x.png" />
            <div className="aiTextLightsMobile"><p className="textAiLightsMobile">Control your lights through AI</p></div>
        </div>
        {/* image hover lights code end on january 18th by rohith */}
    </>
  )
}


export default  Clicks