import {useState, useCallback } from "react";
import {Link,Routes,Route,useLocation,useNavigate} from "react-router-dom"
import {MyContext} from "../components/MyContext/index";
import ContactUsPopup from "../components/ContactUsPopup";
import PortalPopup from "../components/PortalPopup";
import ProductDetails from "../components/ProductDetails/index";
import LightDarkModes from "../components/LightDarkModes/index";
import NotFound from "../components/NotFound/index";

import styles from "./Responsive.module.css";
// import underDevImage from "../images/dev.png"
import Products from "../components/Products/index";
import Main from "../components/Main/index";
import FAQs from '../components/FAQs/index'
import faqsImage from '../images/faqsArrow.png'




const Responsive = (props) => {
  const { openNavCross,isInLightMode,isInDarkMode,setisInLightMode,setisInDarkMode } =props

  // console.log(props)
  const [isContactUsPopupOpen, setContactUsPopupOpen] = useState(false);

 
  const location = useLocation();
  // const currentPath = location.pathname
  // console.log(currentPath,'currentPath In Responsive Component')
  const navigate = useNavigate();


  const onAboutUsClick = useCallback(() => {
    // Calculate the height of the fixed header
    const headerHeight = document.querySelector('header').offsetHeight;
  
    // Navigate to the home route
    navigate("/");

    // Scroll to the About Us section if present, considering the header height
    setTimeout(() => { // Delay to ensure navigation completes before scrolling
      const anchor = document.querySelector("#aboutUs");
      if (anchor) {
        const scrollPosition = anchor.offsetTop - headerHeight;
        window.scrollTo({
          top: scrollPosition,
          behavior: "smooth"
        });
      }
    }, 10);
  }, [navigate]);
 

  const openContactUsPopup = useCallback(() => {
    setContactUsPopupOpen(true);
  }, []);

  const closeContactUsPopup = useCallback(() => {
    setContactUsPopupOpen(false);
  }, []);

  const onMdiinstagramIcon1Click = useCallback(() => {
    window.location.href =
      "https://www.instagram.com/neobrim?utm_source=ig_web_button_share_sheet&igsh=MmVlMjlkMTBhMg==";
  }, []);

  const onPajamastwitterIcon1Click = useCallback(() => {
    window.location.href = "https://twitter.com/_Neobrim_";
  }, []);

  const onBilinkedinIcon1Click = useCallback(() => {
    window.location.href = "https://www.linkedin.com/company/neobrim/";
  }, []);

  const onFefacebookIcon1Click = useCallback(() => {
    window.location.href =
      "https://www.facebook.com/profile.php?id=61554375475494";
  }, []);



  const handleFaqsLinkClick = () => {
    // navigate('/faqs');
      // Scroll to the top below header height when component mounts
      const headerHeight = document.querySelector('header').offsetHeight;
      const faqsContainer = document.getElementById('faqsContainer');
      if (faqsContainer) {
          window.scrollTo({
              top: faqsContainer.offsetTop - headerHeight,
              behavior: 'smooth'
          });
      }
  };



 


  return (  
    <>
      <div className={`${styles.responsive} ${isInLightMode?"":styles["dark-mode"]}`}>
        {/* Start Header code */}
        <header className={`${styles.landingPageHeader} ${styles.fixedHeader} ${styles.underDevImageDesk} ${isInLightMode?"":styles["dark-mode"]}`}>
          <div className={`${styles.logolightmodeParent}`}>
            <div className={styles.logolightmode}>
              <b className={styles.neobrim}>
                <span className={styles.fontLogo}>NEO</span>
                <span className={`${styles.brim} ${styles.fontLogo}`}>BRIM</span>
              </b>
            </div>
            <div className={`${styles.ctaAndOptions} ${isInLightMode?"":styles["dark-mode"]}`}>

             {/* <img src={underDevImage} alt="underDevImageDesktopView" className={styles.underDevImageDesktopView} />  */}
            
              <div className={styles.middleOptionsdarkmode}>

                <LightDarkModes isInLightMode={isInLightMode} 
                isInDarkMode={isInDarkMode} 
                setisInDarkMode={setisInDarkMode} setisInLightMode={setisInLightMode}
                />
      


                <Link
                  to="/"
                  className={`${styles.home} ${
                    location.pathname === "/" ? styles["home-active"] : ""
                  } ${isInLightMode?"":styles["dark-mode"]}`}
                >
                  Home
                </Link>
                <button
                  className={`${styles.home} ${isInLightMode?"":styles["dark-mode"]}`}
                  data-scroll-to="aboutUs"
                  onClick={onAboutUsClick}
                >
                  About us
                </button>
                <Link
                  to="/products"
                  className={`${styles.home} ${styles.proTilt} ${
                    location.pathname === "/products" || location.pathname.startsWith("/products/details/") ? styles["products-active"] : ""
                  } 
                  
                  ${isInLightMode?"":styles["dark-mode"]}`}
                >
                  Products 
                </Link>
                <button className={styles.buttonCta} onClick={openContactUsPopup}>
                <div className={styles.contactUs}>Contact us</div>
              </button>        
              </div>  
              {/* <button className={styles.buttonCta} onClick={openContactUsPopup}>
                <div className={styles.contactUs}>Contact us</div>
              </button> */}
            </div>
            {/* onclick event given start on 16th january by Rohith */}

            {/* Conditionally render navbar/cross icon based on state */}

            <img
              className={`${styles.alignJustifyIcon} ${isInLightMode?"":styles["dark-modeImage"]}`}
              alt=""
              src="/alignjustify@2x.png"
              onClick={openNavCross} // Open NavCross on click
            />
            {/* onclick event given end on 16th january by Rohith */}
          </div>
        </header>
        {/* End Header code */}
        
        
        {/* Start Main content */}
        <Routes>     
          <Route 
            exact
            path="/"
            element={
              <MyContext.Provider value={isInLightMode}>
                <Main openContactUsPopup={openContactUsPopup} />
              </MyContext.Provider>
            }
          />

          <Route exact path="/products" element={ 
            <MyContext.Provider value={isInLightMode}>
              <Products />
            </MyContext.Provider>
          }/>

          <Route exact path="/products/details/:productName/:productId" element={
            <MyContext.Provider value={isInLightMode}>
              <ProductDetails/>
            </MyContext.Provider>
          } />

          <Route
            exact
            path="/faqs"
            element={
            <MyContext.Provider value={isInLightMode}>
              <FAQs/>
            </MyContext.Provider>
            }
          />

          <Route path="*" element={<NotFound/>}/>
        </Routes>
        {/* End Main content */}


        

        {/*Start Footer code */}
        <div className={`${styles.landingPageFooter} ${isInLightMode?styles["landingPageFooterBgLight"]:styles["landingPageFooterBgDark"]}`}>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>


                <div className={styles.frameParent1}>
                  <div className={styles.neobrimParent}>
                    <b className={styles.div}>
                      <span className={styles.fontLogo}>NEO</span>
                      <span className={`${styles.brim} ${styles.fontLogo}`}>BRIM</span>
                    </b>
                    <div className={styles.elevatingHomes}>Elevating Homes</div>
                  </div>
                  <div className={styles.elevatingHomes}>
                    From the house of 21 spheres
                  </div>
                </div>
                <div className={styles.frameParent2}>
                  <div className={styles.frameParent3}>
                    <div className={styles.neobrimGroup}>
                      <b className={styles.div}>
                        <span className={styles.fontLogo}>NEO</span>
                        <span className={`${styles.brim} ${styles.fontLogo}`}>BRIM</span>
                      </b>
                      <div className={styles.elevatingHomes1}>
                        Elevating Homes
                      </div>
                    </div>
                    <div className={styles.elevatingHomes1}>
                      From the house of 21 spheres
                    </div>
                  </div>
                  <div className={styles.mdiinstagramParent}>
                    <img
                      className={`${styles.mdiinstagramIcon} ${isInLightMode?"":styles["dark-modeImage"]}`}
                      alt=""
                      src="/mdiinstagram@2x.png"
                    />
                    <img
                      className={`${styles.mdiinstagramIcon} ${isInLightMode?"":styles["dark-modeImage"]}`}
                      alt=""
                      src="/pajamastwitter@2x.png"
                    />
                    <img
                      className={`${styles.mdiinstagramIcon} ${isInLightMode?"":styles["dark-modeImage"]}`}
                      alt=""
                      src="/bilinkedin@2x.png"
                    />
                    <img
                      className={`${styles.mdiinstagramIcon} ${isInLightMode?"":styles["dark-modeImage"]}`}
                      alt=""
                      src="/fefacebook@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.mdiinstagramGroup}>
                  <img
                    className={`${styles.mdiinstagramIcon1} ${isInLightMode?"":styles["dark-modeImage"]}`}
                    alt=""
                    src="/mdiinstagram@2x.png"
                    onClick={onMdiinstagramIcon1Click}
                  />
                  <img
                    className={`${styles.mdiinstagramIcon1} ${isInLightMode?"":styles["dark-modeImage"]}`}
                    alt=""
                    src="/pajamastwitter@2x.png"
                    onClick={onPajamastwitterIcon1Click}
                  />
                  <img
                    className={`${styles.mdiinstagramIcon1} ${isInLightMode?"":styles["dark-modeImage"]}`}
                    alt=""
                    src="/bilinkedin@2x.png"
                    onClick={onBilinkedinIcon1Click}
                  />
                  <img
                    className={`${styles.mdiinstagramIcon1} ${isInLightMode?"":styles["dark-modeImage"]}`}
                    alt=""
                    src="/fefacebook@2x.png"
                    onClick={onFefacebookIcon1Click}
                  />
                </div>
              </div>
              <div className={styles.contactUsParent}>
                <b className={styles.contactUs1}>Contact us</b>
                <div className={styles.frameParent4}>
                  <div className={styles.frameParent5}>
                    <div className={styles.frameWrapper}>
                      <div className={styles.phoneParent}>
                        <img
                          className={`${styles.mdiinstagramIcon} ${isInLightMode?"":styles["dark-modeImage"]}`}
                          alt=""
                          src="/phone@2x.png"
                        />
                        <div className={`${styles.div} ${isInLightMode?"":styles["dark-mode"]}`}>+91 99131 15333</div>
                      </div>
                    </div>
                    <div className={styles.frameWrapper}>
                      <div className={styles.phoneParent}>
                        <img
                          className={`${styles.mdiinstagramIcon} ${isInLightMode?"":styles["dark-modeImage"]}`}
                          alt=""
                          src="/phone@2x.png"
                        />
                        <div className={`${styles.div} ${isInLightMode?"":styles["dark-mode"]}`}>+91 96879 43901</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.mailParent}>
                    <img
                      className={`${styles.mdiinstagramIcon} ${isInLightMode?"":styles["dark-modeImage"]}`}
                      alt=""
                      src="/mail@2x.png"
                    />
                    <div className={`${styles.div} ${styles.footerMail}`}>info@neobrim.com</div>
                  </div>
                </div>
                <Link to='/faqs' className={styles["faqsNavigationLink"]} onClick={handleFaqsLinkClick}>
                  FAQ’S <img src={faqsImage} className={styles["arrowMark"]} alt="faqs arrow"/>
                </Link>
              </div>
              <div className={styles.contactUsGroup}>
                {/* <div className={styles.contactUs2}>Contact us</div> */}
                <div className={styles.frameParent6}>
                  <div className={styles.frameParent5}>
                  <div className={styles.contactUs2}>Contact us</div>
                    <div className={styles.frameWrapper}>
                      <div className={styles.phoneParent}>
                        <img
                          className={`${styles.phoneIcon2} ${isInLightMode?"":styles["dark-modeImage"]}`}
                          alt=""
                          src="/phone@2x.png"
                        />
                        <div className={`${styles.div} ${isInLightMode?"":styles["dark-mode"]}`}>+91 99131 15333</div>
                      </div>
                    </div>
                    <div className={styles.frameWrapper}>
                      <div className={styles.phoneParent}>
                        <img
                          className={`${styles.phoneIcon2} ${isInLightMode?"":styles["dark-modeImage"]}`}
                          alt=""
                          src="/phone@2x.png"
                        />
                        <div className={`${styles.div} ${isInLightMode?"":styles["dark-mode"]}`}>+91 96879 43901</div>
                      </div>
                    </div>
                    <div className={styles.mailGroup}>
                    <img
                      className={styles.phoneIcon2}
                      alt=""
                      src="/mail@2x.png"
                    />
                    <div className={`${styles.div} ${styles.footerMail}`}>info@neobrim.com</div>
                  </div>
                  </div>
                  {/* <div className={styles.mailGroup}>
                    <img
                      className={styles.phoneIcon2}
                      alt=""
                      src="/mail@2x.png"
                    />
                    <div className={`${styles.div} ${styles.footerMail}`}>info@neobrim.com</div>
                  </div> */}
                </div>
                <Link to='/faqs' className={styles["faqsNavigationLink"]} onClick={handleFaqsLinkClick}>
                  FAQ’S <img src={faqsImage} className={styles["arrowMark"]} alt="faqs arrow"/>
                </Link>
              </div>
              <div className={styles.connectWithUsParent}>
                {/* label element by replacing div for input footer mail on 16th january by Rohith */}
                {/* <label htmlFor="footerMailingBig" className={styles.connectWithUs}>Connect with us</label> */}              
                <p htmlFor="footerMailing" className={`${styles.connectWithUs} ${styles.connectMailFooter}`}>Connect with us</p>
                <div className={styles.frameParent8}>
                  {/*enabled email id- 16th january by Rohith*/}
                  {/* <input type="text" id="footerMailingBig" className={styles.enterYourEmailIdWrapper} placeholder="Enter your email id" /> */}
                  <div className={styles.image17TracedWrapper} onClick={openContactUsPopup}>
                    <img
                      className={styles.image17Traced}
                      alt=""
                      src="/image-17-traced@2x.png"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.connectWithUsGroup}>
                {/* label element by replacing div for input footer mail on 16th january by Rohith */}
                {/* <label htmlFor="footerMailing" className={`${styles.connectWithUs} ${styles.connectMailFooter}`}>Connect with us</label> */}
                <p htmlFor="footerMailing" className={`${styles.connectWithUs} ${styles.connectMailFooter}`}>Connect with us</p>
                <div className={styles.frameParent9}>
                  {/*enabled email id on 16th january by Rohith*/}
                  {/* <input type="text" id="footerMailing" className={styles.enterYourEmailIdContainer} placeholder="Enter your email id" />  */}
                  <div className={styles.image17TracedWrapper} onClick={openContactUsPopup}>
                    <img
                      className={styles.image17Traced1}
                      alt=""
                      src="/image-17-traced@2x.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.copyright21Spheres2024Parent}>
              <div className={`${styles.connectWithUs} ${isInLightMode?"":styles["dark-mode"]}`}>
                Copyright ©️ 21 Spheres 2024 | From the house of 21 Spheres
              </div>
              <div className={`${styles.connectWithUs} ${styles.rishiStyle} ${isInLightMode?"":styles["dark-mode"]}`}>
                Designed with ❤️ by Rishi Ashar
              </div>
            </div>
          </div>
        </div>
        {/*End Footer code */}
        
      </div>

      {/* Start ContactUsPopup */}
      {isContactUsPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeContactUsPopup}
        >
          <ContactUsPopup onClose={closeContactUsPopup} isInLightMode={isInLightMode} />
        </PortalPopup>
      )}
      {/* End ContactUsPopup */}

    </>
  );
};

export default Responsive;
