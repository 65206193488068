import { useContext,useState } from "react";
import axios from 'axios'

/*
useNavigate is a custom hook provided by the react-router-dom library 
for navigating programmatically 
in React functional components. 
It's not part of the React core but 
is often referred to as a hook for 
its usage pattern.
*/
import { useNavigate } from "react-router-dom";


import {MyContext} from "../MyContext/index"; 
import tickImage from '../../images/Vector.png'
import mostPopularTag from '../../images/mostPopularTag.png'
import PackagePopup from '../PackagePopup/index';
import {URL} from '../.././config'

import './index.css'

const PackagesCards=(props)=> {

  // packages data to backend
  const {tabPackageCards} = props
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', phoneNumber: '', city: '',packageTypeValue:'' });
  const [emailValid, setEmailValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const [isNameValid,setIsNameValid] = useState(true)
  const [isCityValid,setIsCityValid] = useState(true)
  const [submissionMessage, setSubmissionMessage] = useState('');
  const [erroRedOrGreen,setErroRedOrGreen] = useState(true)
  // packages data to backend



  const isInLightModeContextValue = useContext(MyContext)


  // Call the useNavigate hook to get the navigate function.
  const navigate = useNavigate()
  // console.log(navigate)


  const {standard,plus,premium} = tabPackageCards
  const {simpleFirstPara,simpleSecondPara,simpleThirdPara,simpleRupee}=standard
  const {plusFirstPara,plusSecondPara,plusRupee}=plus 
  const {premiumFirstPara,premiumSecondPara,premiumThirdPara,premiumRupee}=premium

  
  const handleCustomizeButtonClick = () => {
    // Inside the event handler, use the navigate function to navigate to the products route (/products).
    navigate("/products");
  };

  const handleEventChoose =(event)=>{
    console.log(event.target.value)
    setFormData({...formData,packageTypeValue:event.target.value})
    setShowModal(true);
  }

  const handleSendButtonClick = async () => {

    if (!validateInputs()) return;

    const requestData = {
      ...formData,
      houseType: tabPackageCards.tab,
      packageTypeValue: formData.packageTypeValue,
    };
    console.log(requestData)



    // Send requestData to backend API using fetch or Axios
    try {
      const responseCustomer = await axios.post(`${URL}/users/customer`,requestData)
      // console.log(responseCustomer)

        // Check if the response contains an error 
        if (responseCustomer.data.err) {

          setErroRedOrGreen(false)
          // Display error message from the server
          setSubmissionMessage(responseCustomer.data.err);
        } else {
          // Reset the form data after successful submission
          setFormData({ name: '', email: '', phoneNumber: '', city: '',packageTypeValue:'' });
          setErroRedOrGreen(true)
          // Display success message
          setSubmissionMessage('Details Sent Successfully! We will get back to you soon.');
        }
        
    } catch (error) {
      console.log('Error submitting data:', error.message);
      setErroRedOrGreen(false)
      setSubmissionMessage('Error while submitting data');
    }
    // setShowModal(false);
  };


  const validateInputs = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\d{10}$/;
  
    setEmailValid(emailPattern.test(formData.email));
    setPhoneValid(phonePattern.test(formData.phoneNumber));
    setIsNameValid(formData.name.trim() !== '');
    setIsCityValid(formData.city.trim() !== '');
  
    return (
      formData.name.trim() !== '' &&
      formData.city.trim() !== '' &&
      emailPattern.test(formData.email) &&
      phonePattern.test(formData.phoneNumber)
    );
  };
  
  return (
    <>
      <div className='main-package-card simple-container'>
        <p className={`card-head-package ${isInLightModeContextValue?'':'text-dark-mode'}`}>Standard</p>
        <p className={`card-para-packages ${isInLightModeContextValue?'':'text-dark-mode'}`}><img src={tickImage} alt='tick image' /> {simpleFirstPara}</p>
        <p className={`card-para-packages ${isInLightModeContextValue?'':'text-dark-mode'}`}><img src={tickImage} alt='tick image' /> {simpleSecondPara}</p>
        <p className={`card-para-packages first-card-package-para ${isInLightModeContextValue?'':'text-dark-mode'}`}><img src={tickImage} alt='tick image' /> {simpleThirdPara}</p>
        <div className='rupee-container'>
            <p className={`rupee-para-packages ${isInLightModeContextValue?'':'text-dark-mode'}`}>{simpleRupee}</p>
            <p className={`star ${isInLightModeContextValue?'':'text-dark-mode'}`}>*</p>
        </div>

        <button className='choose-button' value='Standard' onClick={handleEventChoose}>Choose</button>
      </div>

      <div className='main-package-card plus-container'>
        <img src={mostPopularTag} alt='most popular tag' className='most-popular-tag' />
        <p className={`card-head-package ${isInLightModeContextValue?'':'text-dark-mode'}`}>Plus</p>
        <p className={`card-para-packages ${isInLightModeContextValue?'':'text-dark-mode'}`}><img src={tickImage} alt='tick image' /> {plusFirstPara}</p>
        <p className={`card-para-packages plus-para-gap ${isInLightModeContextValue?'':'text-dark-mode'}`}><img src={tickImage} alt='tick image' /> {plusSecondPara}</p>
        <div className='rupee-container'>
            <p className={`rupee-para-packages ${isInLightModeContextValue?'':'text-dark-mode'}`}>{plusRupee}</p>
            <p className={`star ${isInLightModeContextValue?'':'text-dark-mode'}`}>*</p>
        </div>
        <button className='choose-button' value='Plus' onClick={handleEventChoose}>Choose</button>
      </div>

      <div className='main-package-card premium-container'>
        <p className={`card-head-package ${isInLightModeContextValue?'':'text-dark-mode'}`}>Premium</p>
        <p className={`card-para-packages ${isInLightModeContextValue?'':'text-dark-mode'}`}><img src={tickImage} alt='tick image' /> {premiumFirstPara}</p>
        <p className={`card-para-packages ${isInLightModeContextValue?'':'text-dark-mode'}`}><img src={tickImage} alt='tick image' /> {premiumSecondPara}</p>
        <p className={`card-para-packages third-para-package ${isInLightModeContextValue?'':'text-dark-mode'}`}><img src={tickImage} alt='tick image' /> {premiumThirdPara}</p>
        <div className='rupee-container'>
            <p className={`rupee-para-packages ${isInLightModeContextValue?'':'text-dark-mode'}`}>{premiumRupee}</p>
            <p className={`star ${isInLightModeContextValue?'':'text-dark-mode'}`}>*</p>
        </div>
        <button className='choose-button' value='Premium' onClick={handleEventChoose}>Choose</button>
      </div>

      <div className='main-package-card custom-container'>
        <p className='card-head-package'>Custom</p>
        <div className='custom-card-para-bg'>
        <p className='card-para-packages'>Achieve a tailored fit for your requirements with our customizable package options, designed to perfectly match your needs.</p>
        </div>
        <button className={`choose-button customise-btn ${isInLightModeContextValue?'':'dark-mode-customise-button'}`} onClick={handleCustomizeButtonClick}>Customise</button>
      </div>
    
      <PackagePopup
        showModal={showModal}
        setShowModal={setShowModal}
        formData={formData}
        setFormData={setFormData}
        emailValid={emailValid}
        phoneValid={phoneValid}
        isCityValid={isCityValid}
        isNameValid={isNameValid}
        submissionMessage={submissionMessage}
        erroRedOrGreen={erroRedOrGreen}
        handleSendButtonClick={handleSendButtonClick}
        setEmailValid={setEmailValid}
        setPhoneValid={setPhoneValid}
        setIsNameValid={setIsNameValid}
        setIsCityValid={setIsCityValid}
        setErroRedOrGreen={setErroRedOrGreen}
        setSubmissionMessage={setSubmissionMessage}
      />

    </>
  )
}

export default PackagesCards

