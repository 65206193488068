import { useContext } from "react";
import {MyContext} from "../MyContext/index"; 
import './index.css'

const PackagesTabsSwitch=(props)=> {

  const {eachTab,tabClickedTriggered, tabPackageCards} = props  
  const {id,tab} = eachTab

  const isInLightModeContextValue = useContext(MyContext)
  
  const tabClicked =()=>{
    tabClickedTriggered(id)
  }

  return (
    <button type='button' className={`tabSwitchbtn ${tabPackageCards ? 'active' : ''} ${isInLightModeContextValue?'':'textstyle-dark-mode'}`} onClick={tabClicked}>
      {tab}
    </button>
  )
}

export default PackagesTabsSwitch
