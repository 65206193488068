import { useState,useContext,useEffect } from 'react';

import {MyContext} from "../MyContext/index"; 
import {v4 as uuidv4} from 'uuid'
import FAQsQuestionsList from '../FAQsQuestionsList/index'
// import styles from "../../pages/Responsive.module.css";
import './index.css'

const FAQsListOfObjects=[
  {
      id:uuidv4(),
      question:"What is home automation?",
      answer:"Home automation refers to the use of smart devices and technology to control and automate various aspects of your home, such as lighting, security, climate, and entertainment systems. It enables you to manage and monitor your home remotely, providing convenience, energy efficiency, and enhanced security."
  },
  {
      id:uuidv4(),
      question:"Why should I consider home automation for my residence or Office?",
      answer:"Home automation offers numerous benefits, including increased energy efficiency, enhanced security, convenience, and comfort. With the ability to control and monitor your home remotely, you can also enjoy peace of mind, especially when it comes to security."
  },
  {
      id:uuidv4(),
      question:"What kind of devices can be automated in a smart home?",
      answer:"Virtually every aspect of your home can be automated, including lighting, ACs, door locks, entertainment systems and more. Smart home devices are designed to seamlessly integrate and communicate with each other, creating a cohesive and interconnected home environment."
  },
  {
      id:uuidv4(),
      question:"Are home automation systems compatible with existing infrastructure in Indian homes?",
      answer:"Yes, our home automation systems are designed to be compatible with existing infrastructure. However, it's essential to choose devices that work well together and are compatible with the communication protocols commonly used in India, such as Wi-Fi and Zigbee."
  },
  {
      id:uuidv4(),
      question:"How can home automation improve energy efficiency in Indian households?",
      answer:"Home automation enables better control over lighting, heating, and cooling systems, allowing you to optimize energy usage. Smart thermostats, energy-efficient lighting, and automated schedules contribute to reducing energy consumption and, subsequently, lowering electricity bills."
  },
  {
      id:uuidv4(),
      question:"Can I control my home automation system remotely?",
      answer:"Yes, one of the key advantages of home automation is remote control. Using a smartphone app or a web interface, you can monitor and control your smart home devices from anywhere with an internet connection."
  },
  {
      id:uuidv4(),
      question:"How easy is it to install and set up a home automation system?",
      answer:"The ease of installation depends on the complexity of the system and the devices chosen. Many devices are designed for easy installation and can be set up without professional help. However, for more complex systems, professional installation may be recommended."
  },
  {
      id:uuidv4(),
      question:"Are there privacy concerns with home automation systems?",
      answer:"Privacy is a significant consideration, and reputable home automation systems prioritize user data security. It's crucial to choose products from trustworthy manufacturers and follow best practices for securing your smart home network, such as using strong passwords and keeping software up to date."
  },
  {
      id:uuidv4(),
      question:"What sets NEOBRIM's home automation apart?",
      answer:"NEOBRIM offers cutting-edge home automation solutions designed specifically for the Indian market. Our systems seamlessly integrate with the unique infrastructure found in Indian homes, providing tailored solutions for lighting, security, climate control, and entertainment systems."
  },
  {
      id:uuidv4(),
      question:"Is home automation worth the investment?",
      answer:"Absolutely. NEOBRIM's home automation solutions not only add convenience to your daily life but also contribute to energy savings and enhanced security. The long-term benefits make it a worthwhile investment, providing a smarter and more efficient living experience."
  }
];

 
const FAQs = () => {
    const [isOpen, setIsOpen] = useState({}); 
    const isInLightModeContextValue = useContext(MyContext)

    useEffect(() => {
      // Scroll to the top below header height when component mounts
      const headerHeight = document.querySelector('header').offsetHeight;
      const faqsContainer = document.getElementById('faqsContainer');
      if (faqsContainer) {
          window.scrollTo({
              top: faqsContainer.offsetTop - headerHeight,
              behavior: 'smooth'
          });
      }
    }, []);
  
    const handleToggle = (id) => {
      setIsOpen({ ...isOpen, [id]: !isOpen[id] }); 
    };
  
    return (
      <div className="mainBgFaqs" id="faqsContainer">
        <p className={`firstFaqsHead ${isInLightModeContextValue?'':"darkModeGradientTextFaqs"}`}>Frequently asked Questions</p>
        <div className="boldBg">
          <p className="boldFaqsOuter">
            <span className="boldFaqs">YOU HAVE</span> QUESTIONS
          </p>
          <p className="boldFaqsOuter">
            <span className="boldFaqs">WE HAVE</span> ANSWERS
          </p>
        </div>
  
        {FAQsListOfObjects.map((eachQAObject) => (
          <FAQsQuestionsList
            key={eachQAObject.id}
            eachQAObject={eachQAObject}
            isOpen={isOpen[eachQAObject.id] || false} 
            onToggle={handleToggle} 
          />
        ))}
      </div>
    );
};
  
export default FAQs;
