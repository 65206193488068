import { useEffect,useState } from "react";
// import axios from "axios";
import {
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Responsive from "./pages/Responsive";
import NavCross from "./components/NavCross";


function App(props) {
  // console.log(props)
  const [isNavCrossOpen, setNavCrossOpen] = useState(false);
  const [isInLightMode,setisInLightMode] = useState(true)
  const [isInDarkMode,setisInDarkMode] = useState(false)

 



  const openNavCross = () => {
    setNavCrossOpen(true);
  };

  const closeNavCross = () => {
    setNavCrossOpen(false);
  };

  const navigateToRoute = (route) => {
    // Code to navigate to the specified route using React Router
    // For example:
    // history.push(route);
    // You can use history.push() or any other method provided by React Router
    console.log(route)
    setNavCrossOpen(false); // Close NavCross after navigation
  };

  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);



  return (
    <>
      {isNavCrossOpen ? (
        <NavCross
          closeNavCross={closeNavCross}
          isInLightMode={isInLightMode}
          setisInLightMode={setisInLightMode}
          setisInDarkMode={setisInDarkMode}
          isInDarkMode={isInDarkMode}
          navigateToRoute={navigateToRoute}
        />
      ) : (
        <Responsive
          openNavCross={openNavCross}
          isInLightMode={isInLightMode}
          isInDarkMode={isInDarkMode}
          setisInLightMode={setisInLightMode}
          setisInDarkMode={setisInDarkMode}
          
        />
      )}
    </>
  );
    
  
}
export default App;

