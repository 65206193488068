import { useContext } from "react";
import {MyContext} from "../MyContext/index"; 
import './index.css'
import styles from "../../pages/Responsive.module.css";

const About=()=> {
  const isInLightModeContextValue = useContext(MyContext)
  // console.log(isInLightModeContextValue,"In About component")
  return (
  <div id="aboutUs" className={`${styles.aboutUs1} aboutUsSectionTop ${isInLightModeContextValue?"":styles["dark-mode"]}`}>
    <div className={`${styles.aboutUs2} ${isInLightModeContextValue?'':"darkModeGradientText"}`}>About us</div>
    <div className={`${styles.frameParent} ${isInLightModeContextValue?'':"dashed-border"}`}>
      <div className={styles.weUnderstandThatYourHomeIParent}>
        <div className={styles.weUnderstandThatContainer}>
          <span
            className={styles.weUnderstandThat}
          >{`We understand that your home is not just a place;  it's an extension of who you are. That's why we are dedicated to bringing you the latest in `}</span>
          <b className={styles.brim}>Home Automation</b>
          <span className={styles.weUnderstandThat}>
            , seamlessly integrating technology to enhance your lifestyle.
          </span>
        </div>
        <img className={styles.broIconWatchingTv} alt="broIconWatchingTv" src="/bro.png" />
        {/* <img className={styles.bro3Icon} alt="" src="/bro-3@2x.png" />
        <img className={styles.bro3Icon1} alt="" src="/bro-3@2x.png" /> */}
      </div>

      <div className={styles.bro3Parent}>
        {/* <img className={styles.bro3Icon2} alt="" src="/bro-3@2x.png" /> */}
        {/* <img className={styles.bro1Icon} alt="" src="/bro-1@2x.png" /> */}
        <img className={styles.broIconStanding} alt="broIconStanding" src="/bro1.png" />
        <div className={styles.atNeobrimWereContainer}>
          <span>{`At `}</span>  
          <span className={`${styles.brim} ${styles.boldNeobrimLogo}`}>NEOBRIM</span>
          <span className="weAreStyle">{`, we're not merely offering gadgets; we're curating an experience that elevates your everyday living. Your home is a sanctuary, a space that should resonate with your modern sensibilities, adapt to your needs, and reflect your personality. `}</span>
        </div>
      </div>

      <div className={styles.neobrimIsNotJustABrandIWrapper}>
      
        <div className={styles.neobrimIsNotContainer}>
          <span className={styles.boldNeobrimLogo}>NEOBRIM</span>
          <span className={`${styles.isNotJust} ${isInLightModeContextValue?"":styles["dark-mode"]}`}>
            {" "}
            is not just a brand; it's a commitment to making your home as
            modern as you are. Our solutions are not just about
            technology; they are about making your daily life simpler,
            more comfortable, and, most importantly, tailored to you.
          </span>
        </div>

      </div>

    </div>

  </div>
  )
}


export default About