import { useState,useEffect } from 'react'
import {ThreeDots} from 'react-loader-spinner'
import axios from 'axios'
import { Navigate, useParams } from 'react-router-dom'
import {URL} from '../.././config'


import EachProductCard from '../EachProductCard/index'
import PackagePopup from '../PackagePopup/index'
import SuccessViewProductDetails from '../SuccessViewProductDetails/index'

import './index.css'


import ScenarioController from '../../images/scenarioController.png'
import RetroFit from '../../images/retroFit.png'
import CurtainMotor  from '../../images/curtainMotor.png'
import IrBlaster from '../../images/irBlaster.png'





const apiStatusConstants = {
    initial : 'INITIAL',
    loading:'IN_PROGRESS',
    success:'SUCCESS',
    failure:'FAILURE'
  }



const ProductDetails = () => {
  const [selectedProduct, setSelectedProduct] = useState({});
  const [remainingProducts, setRemainingProducts] = useState([]);
  const [productRetrieveStatus,setProductRetrieveStatus] = useState(null)
  const [showPopupDetail,setShowPopupDetail] = useState(false)
  const [formData, setFormData] = useState({ name: '', email: '', phoneNumber: '', city: '',productNameValue:'' });
  const [emailValid, setEmailValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const [isNameValid,setIsNameValid] = useState(true)
  const [isCityValid,setIsCityValid] = useState(true)
  const [submissionMessage, setSubmissionMessage] = useState('');
  const [erroRedOrGreen,setErroRedOrGreen] = useState(true)
  const [optionValue,setOptionValue]=useState(null)

  
  


   
  
  

  /*
  The useParams hook returns an object of key/value pairs 
  of the dynamic params from the current URL that were matched 
  by the <Route path>. Child routes inherit all params from 
  their parent routes.

  useParams can be used to retrieve route parameters from any 
  component that is rendered within a <Route> component provided 
  by React Router. 
  */
  const params = useParams()  
  // console.log('params in ProductDetails Component',params)
  const {productId} = params
  // console.log(productId)

  

  useEffect(() => {

    const fetchProductDetails = async () => {
      try {
        setProductRetrieveStatus(apiStatusConstants.loading)
        
        const response = await axios.get(`${URL}/products/getAllProducts`);
        const {data,statusText} = response

        if(statusText==='OK'){

        /*
        The find() method returns the first item's value that satisfies the provided testing function.
        If no item is found, it returns undefined.
        Syntax: arr.find(Testing Function)
        */
        const selectedItem = data.find((eachProduct)=>eachProduct._id===productId)
        setSelectedProduct(selectedItem)


        /*
        filter(): The filter() method creates a new array by filtering out elements from the
        original array based on a provided function that defines the filtering criteria.
        It returns a new array containing only the elements that pass the condition.
        const newArray = originalArray.filter(callback(currentValue, index, array));
        */
        const remainingItems = data.filter((eachProduct)=>eachProduct._id!==productId)
        const transformedRemainingItems = remainingItems.map(product => {
          const { _id, ...rest } = product;
          return { id: _id, ...rest };
        });
        setRemainingProducts(transformedRemainingItems)
        setProductRetrieveStatus(apiStatusConstants.success)
        }else{
            setProductRetrieveStatus(apiStatusConstants.failure)
        }

      } catch (error) {
        console.error('Error fetching product details:', error.message);
        setProductRetrieveStatus(apiStatusConstants.failure)
      }
    };

    fetchProductDetails();
  }, [productId]);

   // console.log(selectedProduct)
  // console.log(remainingProducts)

  const selectedProductEnquireNowClicked=()=>{
    // console.log('selected Product Enquire Now button Clicked')
    if(selectedProduct.description!==''){
      setOptionValue(selectedProduct.description)
    }
    setFormData({...formData,productNameValue:selectedProduct.name})
    setShowPopupDetail(true)
  }

  const handleSendButtonClick = async () => {

    if (!validateInputs()) return;

    const requestData = {
      ...formData,
      productNameValue:formData.productNameValue,
      productTypeValue:optionValue,
      // productTypeValue: selectedProduct.options[0].value,
    };
    // console.log(requestData)



    // Send requestData to backend API using fetch or Axios
    try {
      const responseCustomer = await axios.post(`${URL}/users/customizedUser`,requestData)
      console.log(responseCustomer)

        // Check if the response contains an error 
        if (responseCustomer.data.err) {

          setErroRedOrGreen(false)
          // Display error message from the server
          setSubmissionMessage(responseCustomer.data.err);
        } else {
          // Reset the form data after successful submission
          setFormData({ name: '', email: '', phoneNumber: '', city: '' });
          setErroRedOrGreen(true)
          // Display success message
          setSubmissionMessage('Details Sent Successfully! We will get back to you soon.');
        }
        
    } catch (error) {
      console.log('Error submitting data:', error.message);
      setErroRedOrGreen(false)
      setSubmissionMessage('Error while submitting data');
    }
    
  };



  const validateInputs = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\d{10}$/;
  
    setEmailValid(emailPattern.test(formData.email));
    setPhoneValid(phonePattern.test(formData.phoneNumber));
    setIsNameValid(formData.name.trim() !== '');
    setIsCityValid(formData.city.trim() !== '');
  
    return (
      formData.name.trim() !== '' &&
      formData.city.trim() !== '' &&
      emailPattern.test(formData.email) &&
      phonePattern.test(formData.phoneNumber)
    );
  };



  const renderLoadingView=()=>(
    <div>
      <ThreeDots type="ThreeDots" color="#6D54F1" height={80} width={80} />
    </div>
  )
  
    const renderSuccessView=()=>{
      if (!selectedProduct) {
        return <Navigate to="*"/>
      }
        const {name,image} = selectedProduct
        
        return(    
              <>
                <div className='selected-bg'>
                  <div className='selected-image-buttons-card'>
                    <img src={image} alt={name} className='selected-image' />
                    <div className='buttons-selected-product'> 
                      <button type='button' className='add-btn-selected-product'>Add to Package</button>
                      <button type='button' className='enquire-btn-selected-product' onClick={selectedProductEnquireNowClicked}>Enquire Now</button>
                    </div>
                  </div>
                  <SuccessViewProductDetails selectedProduct={selectedProduct} setOptionValue={setOptionValue}/>
                  <div className='buttons-selected-product-mobile'> 
                      <button type='button' className='add-btn-selected-product'>Add to Package</button>
                      <button type='button' className='enquire-btn-selected-product' onClick={selectedProductEnquireNowClicked}>Enquire Now</button>
                  </div>
                </div>

                <div className='remaining-bg'>
                  {remainingProducts.slice(0, 2).map((eachProduct) => (
                    <EachProductCard key={eachProduct.id} eachProduct={eachProduct} />
                  ))}
                </div>

                <PackagePopup
                 showPopupDetail={showPopupDetail}
                 formData={formData}
                 setFormData={setFormData}
                 emailValid={emailValid}
                 phoneValid={phoneValid}
                 isCityValid={isCityValid}
                 isNameValid={isNameValid}
                 submissionMessage={submissionMessage}
                 erroRedOrGreen={erroRedOrGreen}
                 setShowPopupDetail={setShowPopupDetail}
                 setEmailValid={setEmailValid}
                 setPhoneValid={setPhoneValid}
                 setIsNameValid={setIsNameValid}
                 setIsCityValid={setIsCityValid}
                 setErroRedOrGreen={setErroRedOrGreen}
                 setSubmissionMessage={setSubmissionMessage}
                 handleSendButtonClick={handleSendButtonClick}
                />
              </>
        )    
    }
       

    const renderFailureView=()=>(
      <h1>Something Went Wrong,Please Try Again After Sometime.</h1>
    )
  
  
    const getFinalProducts=()=>{
      switch (productRetrieveStatus) {
        case apiStatusConstants.loading:
          return renderLoadingView()
    
        case apiStatusConstants.success:
          return renderSuccessView()
          
        case apiStatusConstants.failure:
          return renderFailureView()
    
        default:
          return null
      }      
    }

  

  return (
    <div className='bg'>
        {getFinalProducts()}
    </div>
  )
}

export default ProductDetails
