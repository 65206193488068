import { useState, useCallback } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import ContactUsPopup from "../ContactUsPopup";
import PortalPopup from "../PortalPopup";
import LightDarkModes from "../LightDarkModes/index";
import styles from "../../pages/Responsive.module.css";
import "./index.css";


const NavCross = ({ closeNavCross,isInLightMode,isInDarkMode,setisInDarkMode,setisInLightMode }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isContactUsPopupOpen, setContactUsPopupOpen] = useState(false);

  const openContactUsPopup = useCallback(() => {
    setContactUsPopupOpen(true);
  }, []);

  const closeContactUsPopup = useCallback(() => {
    setContactUsPopupOpen(false);
  }, []);

  const handleNavigation = (route) => {
    navigate(route);
    closeNavCross(); // Close the navigation menu after navigating
  };

  const scrollToAboutUs = useCallback(() => {
    const headerHeight = document.querySelector('header').offsetHeight;
    const anchor = document.querySelector("#aboutUs");
    if (anchor) {
      const scrollPosition = anchor.offsetTop - headerHeight;
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth"
      });
    }
  }, []);

  const handleAboutUsClick = useCallback(() => {
    handleNavigation("/");
    setTimeout(scrollToAboutUs, 10); // Delay to ensure navigation completes before scrolling
  }, [handleNavigation, scrollToAboutUs]);

  console.log("NavClicks",isInLightMode)

  return (
    <>
      <div className={`navElBgCross ${isInLightMode ? 'light-mode' : 'dark-mode'}`}>
        <img src="/cross-icon.png" alt="Cross Icon" className={`crossimage ${isInLightMode?"":styles["dark-modeImage"]}`} onClick={closeNavCross} />

        <div className={`navEls`}>

          <div className="lightDarkBg">
          <LightDarkModes isInLightMode={isInLightMode} 
            isInDarkMode={isInDarkMode} 
            setisInDarkMode={setisInDarkMode} setisInLightMode={setisInLightMode}
          
          />
          </div>


          <Link to="/" className={`${styles.home} ${styles.navElement} ${
            location.pathname === "/" ? "home-active" : ""
          } navElement ${isInLightMode ? 'light-mode' : 'dark-mode'}`} onClick={() => handleNavigation("/")}>
            Home
          </Link>

          <button className={`${styles.home} ${styles.navElement} navElement ${isInLightMode ? 'light-mode' : 'dark-mode'}`} onClick={handleAboutUsClick}>
            About Us
          </button>

          <Link to="/products" className={`${styles.home} ${styles.navElement} ${
            location.pathname === "/products" || location.pathname.startsWith("/products/details/")  ?"products-active" : ""
          } navElement ${isInLightMode ? 'light-mode' : 'dark-mode'}`} onClick={() => handleNavigation("/products")}>
            Products
          </Link>

          <button className={`${styles.buttonCta}`} onClick={openContactUsPopup}>
            <div className={styles.contactUs}>Contact us</div>
          </button>
        </div>
      </div>
      {/* Start ContactUsPopup */}
      {isContactUsPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeContactUsPopup}
        >
          <ContactUsPopup onClose={closeContactUsPopup} isInLightMode={isInLightMode} />
        </PortalPopup>
      )}
      {/* End ContactUsPopup */}
    </>
  );
};

export default NavCross;
