
import {useState,useContext} from 'react'
import {MyContext} from "../MyContext/index"; 
import {v4 as uuidv4} from 'uuid'
import PackagesTabsSwitch from '../PackagesTabsSwitch/index';
import PackagesCards from '../PackagesCards/index';
import './index.css';

const packagesTabswitchDetails=[
    {
        id:uuidv4(),
        tab:"1BHK",
        standard:{
            simpleFirstPara:'12 Touch Switches + 1 Fan',
            simpleSecondPara:'8 In Wall modules',
            simpleThirdPara:'1 IR blaster',
            simpleRupee:'₹36,671',
        },
        plus:{
            plusFirstPara:'27 Touch Switches + 5 Fan',
            plusSecondPara:'2 IR blaster',
            plusRupee:"₹48,184",
            topRightTag:'Most popular',
            
        },
        premium:{
            premiumFirstPara:'35 Premium Touch Switches + 4 Fan',
            premiumSecondPara:'2 IR Blaster',
            premiumThirdPara:'1 Curtain',
            premiumRupee:'₹96,466',
            
        }
    },
    {
        id:uuidv4(),
        tab:"2BHK",
        standard:{
            simpleFirstPara:'17 Touch Switches + 1 Fan',
            simpleSecondPara:'10 In Wall modules',
            simpleThirdPara:'1 IR blaster',
            simpleRupee:'₹46,903',
            
        },
        plus:{
            plusFirstPara:'34 Touch Switches + 6 Fan',
            plusSecondPara:'3 IR blaster',
            plusRupee:'₹59,772',
            topRightTag:'Most popular',
            
        },
        premium:{
            premiumFirstPara:'36 Premium Touch Switches + 5 Fan',
            premiumSecondPara:'3 IR Blaster',
            premiumThirdPara:'1 Curtain',
            premiumRupee:'₹1,19,400',
        }
    },
    {
        id:uuidv4(),
        tab:"3BHK",
        standard:{
            simpleFirstPara:'32 Touch Switches + 3 Fan',
            simpleSecondPara:'12 In Wall modules',
            simpleThirdPara:'1 IR blaster',
            simpleRupee:'₹58,134',
            
        },
        plus:{
            plusFirstPara:'42 Touch Switches + 7 Fan',
            plusSecondPara:'4 IR blaster',
            plusRupee:'₹71,359',
            topRightTag:'Most popular',
            
        },
        premium:{
            premiumFirstPara:'52 Premium Touch Switches + 6 Fan',
            premiumSecondPara:'4 IR Blaster',
            premiumThirdPara:'1 Curtain',
            premiumRupee:'₹1,42,334',
            
        }
    }
]
    


const PackagesSection = () => {
  
  const [tabPackageCards,setTabPackageCards] = useState(packagesTabswitchDetails[0]) 
  const [activeTabId, setActiveTabId] = useState(packagesTabswitchDetails[0].id);

  const isInLightModeContextValue = useContext(MyContext)

  const tabClickedTriggered=(idNum)=>{
    const activeTabCardDetails = packagesTabswitchDetails.filter((eachObject)=>(
        eachObject.id===idNum
    ))
    // console.log(activeTabCardDetails) // array of single object
    setTabPackageCards(activeTabCardDetails[0])
    setActiveTabId(idNum);
  }

  return (
    <div className="packages-section">
      <h1 className={`section-title ${isInLightModeContextValue?'':'dark-packages-head'}`}>Simple, Straightforward Pricing</h1>
      <div className="packages-container">
        {
            packagesTabswitchDetails.map((eachTab)=>(
                <PackagesTabsSwitch tabPackageCards={eachTab.id===activeTabId} eachTab={eachTab} tabClickedTriggered={tabClickedTriggered} key={eachTab.id} />
            ))
        }
      </div>
      <div className='packages-cards-container'>
        <PackagesCards tabPackageCards={tabPackageCards} />
      </div>

      <div className='footer-para-packages'>
        <p className={`footer-para ${isInLightModeContextValue?'':'dark-packages-para'}`}>If your residence boasts 4 bedrooms or more, we offer the flexibility to tailor a customized package to suit your specific needs.</p>
        <button className='footer-package-btn' type='button'>Let’s make it!</button>
      </div>
    </div>
  );
};

export default PackagesSection;
