import {configureStore} from '@reduxjs/toolkit'

import cartReducer from './cartSliceReducer'

const store = configureStore({
    reducer:{
        mainCartReducer:cartReducer,
    },
})

export default store
