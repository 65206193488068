import { useContext,useEffect,useState } from 'react'
// import {v4 as uuidv4} from 'uuid'
import {ThreeDots} from 'react-loader-spinner'

import { MyContext } from '../MyContext/index'
import {URL} from '../.././config'

import EachProductCard from '../EachProductCard/index'

// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'

import ScenarioController from '../../images/scenarioController.png'
import RetroFit from '../../images/retroFit.png'
import CurtainMotor  from '../../images/curtainMotor.png'
import IrBlaster from '../../images/irBlaster.png'



import "./index.css"
import axios from 'axios'



const apiStatusConstants = {
  initial : 'INITIAL',
  loading:'IN_PROGRESS',
  success:'SUCCESS',
  failure:'FAILURE'
}

const Products=()=> {

  const [productsDetailsData,setProductsDetailsData]=useState([])
  
  const [productRetrieveStatus,setProductRetrieveStatus] = useState(apiStatusConstants.success)
  const [searchQuery, setSearchQuery] = useState('');
  const isInLightModeContextValue = useContext(MyContext)
  // console.log(isInLightModeContextValue,'in products component')

  useEffect(()=>{

    // console.log('useEffect in Products component')

    const productsCardApi= async()=>{
      try {

        setProductRetrieveStatus(apiStatusConstants.loading)

        const responseProducts = await axios.get(`${URL}/products/getAllProducts`)
        const {data,statusText} = responseProducts
        // console.log('data is',data)
        // console.log('statusText is',statusText)  
        const modifiedData = data.map(item => {
          const { _id, ...rest } = item; // Destructure _id from item
          return { ...rest, id: _id }; // Spread rest of the properties and add id
        });
      
        // console.log("modifiedData",modifiedData);
        
        if(statusText==='OK'){
          setProductRetrieveStatus(apiStatusConstants.success)
          setProductsDetailsData([...modifiedData])
        }else{
          setProductRetrieveStatus(apiStatusConstants.failure)
        }
      } catch (error) {
        console.log(error.message)
        setProductRetrieveStatus(apiStatusConstants.failure)
      }
    }

    productsCardApi()

  },[])


  const renderLoadingView=()=>(
  <div>
    <ThreeDots type="ThreeDots" color="#6D54F1" height={80} width={80} />
  </div>
  )

  const regexSearchQuery = new RegExp(searchQuery,'gi')
  // console.log('regexSearchQuery:',regexSearchQuery) 
  const initialProducts = productsDetailsData.filter((eachProduct)=>(
    eachProduct.name.match(regexSearchQuery)
  ))



  /*
  const initialProducts = productsDetailsData.filter((eachProduct)=>(
    eachProduct.name.toLowerCase().includes(searchQuery.toLowerCase())
  ))
  */


  const renderSuccessView=()=>(
    <>
    {
    initialProducts.length>0 ?
    (
      initialProducts.map((eachProduct) => (
        <EachProductCard key={eachProduct.id}
          eachProduct={eachProduct}
          
        />
      ))
    ):(
    <h1>Search Results Not Found</h1>
    )
    }
  
    </>
  )

  const renderFailureView=()=>(
    <h1>Something Went Wrong,Please Try Again After Sometime.</h1>
  )


  const getFinalProducts=()=>{
    // console.log('getFinalProducts in Products component')
    switch (productRetrieveStatus) {
      case apiStatusConstants.loading:
        // console.log('renderLoadingView in Products/getFinalProducts component')
        return renderLoadingView()
  
      case apiStatusConstants.success:
        // console.log('renderSuccessView in Products/getFinalProducts component')
        return renderSuccessView()
        
      case apiStatusConstants.failure:
        // console.log('renderFailureView in Products/getFinalProducts component')
        return renderFailureView()
  
      default:
        // console.log('default in Products/getFinalProducts component')
        return null
    }
  }

  const handleSearch = (query)=>{
    setSearchQuery(query)
  }

  return (
    <div className="productsBg">
      <div className="productsDevelopmentTextBg">
        <div className='paraBgProducts'>
          <p className={`firstProText ${isInLightModeContextValue?'':'dark-head-products'}`}>Explore our range of <span className='products-span-head'>Exceptional Products</span></p>
          <p className="secondProText">Elevate your lifestyle with our curated selection of products.</p>
        </div>

        <div className="search-bar">
          <input
            type="search"
            placeholder="Search products..."
            value={searchQuery}
            onChange={e => handleSearch(e.target.value)}
          />
        </div>
        


        <div className="product-list">
          {getFinalProducts()}
        </div>
  
      </div>
    </div>
  )
}

export default  Products
