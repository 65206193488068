import { createSlice } from "@reduxjs/toolkit";

const initialStateValue={
    cart:[]
}

const cartSliceReducer = createSlice({
    name:'cartSlice',
    initialState:initialStateValue,
    reducers:{

    }
})

export const ActionCreators = cartSliceReducer.actions

const cartReducer = cartSliceReducer.reducer

export default cartReducer

